import styles from '@/styles/index.module.scss'
import { useRouter,useSearchParams  } from 'next/navigation';
import {GetServerSideProps, NextPage} from 'next';
import {IncomingHttpHeaders} from 'http';
import Router from 'next/router';
import { useState,useEffect } from 'react';
let browser:string;
let browserType:string = '';

type Props = {
    browser: string,
    url:string
  }

export default function Index(props:Props){
    console.log(props)
    const router = useRouter();
    const params = useSearchParams();
    const [showOff,setShowOff] = useState(false);

    useEffect(() => {
        getBrowserType(props.browser);
        const URL= props.url;
        setToken();
        if(browserType === 'weixin'){
            const appid = URL == 'https://testupay.unionstrongtech.com'?'wx79aefc53609cbf55':'wx8a3f3dda26134651';
            let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(`${URL}/wxpay`)}&response_type=code&scope=snsapi_base&state=1#wechat_redirect`;
            window.location.replace(url);
            }else if(browserType === 'ali'){
                Router.replace('/alipay');
            } else {
                setShowOff(true)
            }
    },[])


    const setToken = () => {
        const token = params.get('token')
        if (token){
            const tokenInfo = JSON.stringify(JSON.parse(b64Decode(token))) 
            localStorage.setItem('token',tokenInfo)
       }else{
        localStorage.clear()
       }
    }
    const b64Decode = (str:string) => {
        return decodeURIComponent(atob(str));
    }
    // 获取浏览器类型
    const getBrowserType = (browser:string) => {
        if(/Alipay/i.test(browser)){
            browserType = 'ali' 
        }else if(/micromessenger/i.test(browser)){
            browserType = 'weixin'
        }else{
            browserType = 'other'
        }
    }
    return(
        <div className={styles.main}  style={{backgroundColor: showOff ? 'rgba(0,0,0,0.4)': 'none'}}>
            {
                showOff &&  <div className={styles.offPage}>
                    <p>提示：</p>
                    请使用微信或支付宝扫码！
                    </div>
            }
            {
                !showOff && <div className={styles.main}></div>
            }    
        </div>
        
    )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    const headers:IncomingHttpHeaders = context.req.headers;
    const browser = headers['user-agent'];
    return {
      props: {
        browser,
        url:process.env.BASE_URL
      }
    };
  };
